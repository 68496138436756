import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import MainContainer from '../container/main'
import SEO from '../components/seo'

import Contact from '../components/contact'

import { demoFormContainer } from './demo.module.scss'

const IndexPage = ({ data }) => (
  <MainContainer>
    <SEO title="Basaas Workplace" />
    <div className={`${demoFormContainer}`}>
      <div className="inner-wrapper">
        <div className="content">
          <h1 className="headline">Let’s talk how you can benefit from a Digital Hub and how you can:</h1>
          {/* <span className="subheadline">Get to know Basaas in a personal demo:</span> */}
          <ul className="demo-list" style={{ marginTop: '32px' }}>
            <li className="list-item">Optimize your digital employee experience</li>
            <li className="list-item">Improve your workforce productivity</li>
            <li className="list-item">Manage all apps your employees love</li>
            <li className="list-item">Get all integrations your teams need</li>
            <li className="list-item">Conduct a proof of concept for a secure decision</li>
          </ul>
          <span className="subheadline">We look forward to meeting with you!</span>
          <div className="avatar-container">
            <div className="avatar-item">
              <StaticImage className="avatar-img" src="../assets/images/avatar-frank.jpg" alt="Frank, CEO" placeholder="blurred" />
              <span className="avatar-name">Frank, CEO</span>
            </div>
            <div className="avatar-item">
              <StaticImage className="avatar-img" src="../assets/images/avatar-selim.jpg" alt="Selim, CTO" placeholder="blurred" />
              <span className="avatar-name">Selim, CTO</span>
            </div>
            <div className="avatar-item">
              <StaticImage className="avatar-img" src="../assets/images/avatar-daniel.jpg" alt="Daniel, CEO" placeholder="blurred" />
              <span className="avatar-name">Daniel, CPO</span>
            </div>
          </div>
        </div>
        <div className="form">
          <Contact type="book-demo" />
        </div>
      </div>
    </div>
  </MainContainer>
)

export default IndexPage

// export const query = graphql`
//   query {
//     placeholderLandscape: file(relativePath: { eq: "placeholder-landscape.png" }) {
//       childImageSharp {
//         gatsbyImageData(layout: FULL_WIDTH, quality: 84)
//       }
//     }

//     placeholderPortrait: file(relativePath: { eq: "placeholder-portrait.png" }) {
//       childImageSharp {
//         gatsbyImageData(layout: FULL_WIDTH, quality: 84)
//       }
//     }

//     placeholderSquare: file(relativePath: { eq: "placeholder-square.png" }) {
//       childImageSharp {
//         gatsbyImageData(layout: FIXED, width: 200, quality: 84)
//       }
//     }
//   }
// `

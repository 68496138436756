// extracted by mini-css-extract-plugin
export const demoFormContainer = "demo-module__demoFormContainer--2MEJB";
export const innerWrapper = "inner-wrapper";
export const content = "content";
export const kicker = "kicker";
export const headline = "headline";
export const subheadline = "subheadline";
export const demoList = "demo-list";
export const listItem = "list-item";
export const avatarContainer = "avatar-container";
export const avatarItem = "avatar-item";
export const avatarImg = "avatar-img";
export const avatarName = "avatar-name";
export const form = "form";